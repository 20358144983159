<template>
    <canvas ref="myChart"></canvas>
</template>

<script>
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js'

export default {
    name: 'BarChart',
    props: {
        chartData: Object,
        options: {
            type: Object,
            default() {
                return {
                    legend: true,
                    stacked: false,
                    precision: undefined,
                    yTitle: ''
                }
            }
        },
        items: Array
    },
    data() {
        return {
            barChart: null
        }
    },
    watch: {
        chartData() {
            this.barChart.data = this.chartData
            this.barChart.update()
        },
        items(items) {
            this.barChart.legend.legendItems.forEach((li, index) => {
                const hidden = items.indexOf(li.text) < 0
                li.hidden = hidden
                hidden ? this.barChart.hide(index) : this.barChart.show(index)
            })
            this.barChart.render()
        }
    },
    mounted() {
        Chart.register(
            ArcElement,
            LineElement,
            BarElement,
            PointElement,
            BarController,
            BubbleController,
            DoughnutController,
            LineController,
            PieController,
            PolarAreaController,
            RadarController,
            ScatterController,
            CategoryScale,
            LinearScale,
            LogarithmicScale,
            RadialLinearScale,
            TimeScale,
            TimeSeriesScale,
            Decimation,
            Filler,
            Legend,
            Title,
            Tooltip,
            SubTitle
        )
        this.barChart = new Chart(this.$refs.myChart, {
            type: 'bar',
            data: this.chartData,
            options: {
                elements: {
                    bar: {
                        borderRadius: 0
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        stacked: this.options.stacked,
                        ticks: {
                            maxRotation: 0,
                            autoSkipPadding: 20
                        }
                    },
                    y: {
                        title: {
                            display: this.options.yTitle,
                            text: this.options.yTitle
                        },
                        beginAtZero: true,
                        stacked: this.options.stacked,
                        grid: {
                            drawBorder: false
                        },
                        ticks: {
                            precision: this.options.precision
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title(context) {
                                const [date, year] = context[0].label.split(',')
                                return `${year}-${date}:`
                            },
                            label(context) {
                                return context.formattedValue
                            }
                        }
                    },
                    legend: {
                        display: this.options.legend,
                        labels: {
                            usePointStyle: true
                        },
                        position: 'bottom',
                        onClick: (_, legendItem) => {
                            this.$emit('legendClick', legendItem)
                        }
                    }
                }
            }
        })
    }
}
</script>
